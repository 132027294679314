body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.two-columns {
  display: flex;
  justify-content: space-around;
}

@media (max-width: 768px) {
  .two-columns {
    display: flex;
    justify-content: space-around;
    padding-left: 12px;
  }
}

::selection {
  background: lightgrey;
  color: black;
}
::-moz-selection {
  background: lightgrey;
  color: black;
}


.column {
  list-style-type: disc;
  padding-left: 1rem;
}

/* Top Header */
nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 65px;
  display: flex;
  justify-content: center;
  border: 1px solid black;
  background-color: black;
  z-index: 10; 
}


nav ul {
  list-style: none;
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-left: -30px; 
}

nav a {
  color: white;
  text-decoration: none;
}

.link-size {
  font-size: 24px;
}


@media (max-width: 720px) {
  .link-size {
    font-size: 22px;
    align-items: center;
  }
  nav ul {
    list-style: none;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center; 
  }
}

/* phone size for top banner links */
@media (max-width: 500px) {
  .link-size {
    font-size: 20px;
    align-items: center;
  }
  nav ul {
    list-style: none;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center; 
    margin-left: -30px; 
  }
}

/* phone size for top banner links */
@media (max-width: 420px) {
  .link-size {
    font-size: 16px;
    align-items: center;
  }
  nav ul {
    list-style: none;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center; 
    margin-left: -30px; 
  }
}





nav a:hover {
  text-decoration: underline;
}

/*Content Under Header */
.App {
  padding: 5rem;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.About {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}
.Games {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.Contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0rem;
  color: white;
  width: 100%;
}

/* Image Button */

/*
.image-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
  width: 400px;
  height: 400px;
  box-sizing: border-box;
  overflow: hidden;
}

.image-button:hover {
  opacity: 0.8;
}

.image-button .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #000;
  box-sizing: border-box;
}

.image-button img {
  width: 100%;
  height: calc(100% - 2rem);
  object-fit: cover;
}

.caption {
  width: 100%;
  height: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-top: 1px solid #000;
}
*/

.pdf-link {
  color: blue;
  cursor: pointer;
}

.Projects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
}

.projects-container {
  max-width: 1350px;
  margin: 0 auto;
}

@media (max-width: 1380px) {
  .Projects {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
}

@media (max-width: 900px) {
  .Projects {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
}

.gif-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.gif-image {
  max-width: 100%;
  height: auto;
}


.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*
.umkc-image-container {
  width: 100%;
  max-width: 650px; 
  margin-bottom: 1rem;
}
*/

.profile-image-container {
  width: 100%;
  max-width: 650px; 
  margin-bottom: 1rem;
}

.about-image-container {
  flex-direction: row;
  width: 100%;
  max-width: 500px; 
  margin-bottom: 1rem;
}

.profile-image {
  width: 100%;
  height: auto;
}

/* Large screen */
@media (min-width: 1290px) {
  .home-container {
    flex-direction: row;
  }

  .profile-image-container {
    margin-bottom: 0;
    margin-top: -450px;
  }

  
}
/* Smaller screen */
@media (max-width: 1289px) {
  .home-container {
    flex-direction: row;
  }
  .profile-image-container {
    margin-bottom: 0;
    margin-top: -550px;
    max-width: 450px;
  }
}


@media (max-width: 1150px) {
  .home-container {
    flex-direction: column;
  }
  .profile-image-container {
    margin-bottom: 0;
    margin-top: 20px;
    max-width: 350px;
  }
}

@media (max-width: 890px) {
  .home-container {
    flex-direction: column;
  }
  .profile-image-container {
    margin-bottom: 0;
    margin-top: 10px;
    max-width: 300px;
  }
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-image-container {
  margin-bottom: 0;
  margin-top: 0px;
  max-width: 350px;
}