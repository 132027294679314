.image-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: inherit;
    width: 410px;
    height: 395px;
    box-sizing: border-box;
    overflow: hidden;
    background-size: contain; 
    background-position: center; 
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: fill; 
  }
  
  .image-button:hover {
    opacity: 0.8;
  }
  
  .image-button .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    box-sizing: border-box;
    position: relative; 
  }

  @media (max-width: 505px) {
    .image-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      color: inherit;
      width: 380px;
      height: 360px;
      box-sizing: border-box;
      overflow: hidden;
      object-fit: contain;
      background-size: contain; 
      background-position: center; 
    }
  
    .image-button .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      border: 1px solid #000;
      box-sizing: border-box;
      position: relative; /* Add position relative to ensure proper positioning of caption */
    }
  }

/* phone screen size */
@media (max-width: 480px) {
  .image-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: inherit;
    width: 328px;
    height: 316px;
    box-sizing: border-box;
    overflow: hidden;
    object-fit: contain;
    background-size: contain; 
    background-position: center; 
  }

  .image-button .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    box-sizing: border-box;
    position: relative; /* Add position relative to ensure proper positioning of caption */
  }
}

/* phone screen size */
  @media (max-width: 400px) {
    .image-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      color: inherit;
      width: 228px;
      height: 216px;
      box-sizing: border-box;
      overflow: hidden;
      object-fit: contain;
      background-size: contain; 
      background-position: center; 
    }

    .image-button .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      border: 1px solid #000;
      box-sizing: border-box;
      position: relative; /* Add position relative to ensure proper positioning of caption */
    }
  }
  
  .caption {
    width: 100%;
    height: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    font-weight: bold;
    border-top: 1px solid #000;
    padding-top: 4px;
    padding-bottom: 4px;
    position: absolute;
    bottom: 0;
    margin: 0; /* Add this line to remove any default margin */
    box-sizing: border-box; /* Add this line to include border and padding inside the element's height */
  }
  